export const environment = {
  apiUrl: "https://istu.isu.edu.tw:8099/api/",
  // apiUrl: "http://192.168.11.39:8099/api/",
  production: false,
  pagecount: 50,
  bsDatePickerConfig: {
    isAnimated: true,
    showClearButton: true,
    clearPosition: 'right',
    containerClass: 'theme-dark-blue',
    clearButtonLabel: "清除"
  },
  domain: {
    name: "school2.hl.com.tw",
    homePage: "https://school2.hl.com.tw"
  }
};
